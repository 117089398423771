<template>
  <div>
    <div
      class="text-center"
      >
      <v-icon
        size="150"
        color="success"
        >
        mdi-check-decagram
      </v-icon>
    </div>
    <div
      class="body-1 text-center mt-3"
      >
      ¡Felicitaciones! Has finalizado tu registro con éxito, ahora puedes disfrutar de todos los beneficios que <span class="font-weight-medium">PartnersFans</span> tiene para ofrecerte.
    </div>
    <div
      class="text-center mt-3"
      >
      <v-btn
        class="py-5"
        outlined
        :block="$vuetify.breakpoint.mobile"
        small
        @click="$emit('finish')"
        >
        {{ userType == 'partner' ? 'Ir al panel de mi tienda' : 'Ir al inicio' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userType: {
      required: true,
      type: String
    }
  },
}
</script>
